import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Bookmaker, Match, ModuleScheduleSection } from '@common/clients/api';
import { DateTimeUtil, Format } from '@common/utils/DateTimeUtil';
import { BettingDisclaimer, LogoVariant } from '@web/atoms/BettingDisclaimer';
import { BookmakerLogo } from '@web/atoms/BookmakerLogo';
import { Flexbox } from '@web/atoms/Flexbox';

import { MatchThumbnail } from './atoms/MatchThumbnail';
import { MatchOdds } from './molecules/MatchOdds';

import styles from './MatchBlock.module.scss';

const isToday = (matchDate: Date): boolean => {
    const today = new Date();
    return (
        matchDate.getDate() === today.getDate() &&
        matchDate.getMonth() === today.getMonth() &&
        matchDate.getFullYear() === today.getFullYear()
    );
};

export interface Props {
    bookmaker?: Bookmaker;
    match: Match;
    trackerName: ModuleScheduleSection;
}

export const MatchBlock: FC<Props> = ({
    match,
    bookmaker,
    trackerName = ModuleScheduleSection.ODDS_DOSSIER_HOME,
}) => {
    const __datetime = useTranslation('datetime').t;
    const hasDarkBackground = trackerName === ModuleScheduleSection.ODDS_DOSSIER_HOME;

    const odds = match?.odds ? match.odds[0] : undefined;
    const url = match?.url || bookmaker?.url || '';
    const icon = hasDarkBackground ? bookmaker?.darkBackgroundIcon : bookmaker?.lightBackgroundIcon || '';

    const kickoff = new Date(match?.kickOff || new Date());
    const timeFormatted = DateTimeUtil.format(kickoff, Format.TIME_NUMERIC);
    const dateReadable = isToday(kickoff)
        ? __datetime('today')
        : DateTimeUtil.format(kickoff, Format.DATE_NUMERIC);

    const home = match?.home;
    const away = match?.away;

    return (
        <section
            className={`${styles.MatchBlock} ${styles[`module-${trackerName}`]} ndm-betting-integration-tracker`}
            data-tracker-name={trackerName}
        >
            <div className={styles['club-row']}>
                {home ? <MatchThumbnail classes={styles['row-item']} team={home} match={match} /> : null}
                <Flexbox
                    column
                    as={'div'}
                    classes={`${styles['match-info']} ${styles['row-item']}`}
                    position={'relative'}
                >
                    <h4 className={styles.time}>{timeFormatted}</h4>
                    <span className={styles.date}>{dateReadable}</span>
                    {trackerName === ModuleScheduleSection.ODDS_DOSSIER_HOME ? (
                        <BookmakerLogo
                            alt={bookmaker?.name || ''}
                            url={url || bookmaker?.url || ''}
                            src={icon || ''}
                            className={`${styles['betting-logo']}`}
                        />
                    ) : null}
                </Flexbox>
                {away ? <MatchThumbnail classes={styles['row-item']} team={away} match={match} /> : null}
            </div>
            {odds ? (
                <>
                    <div className={styles['odds-row']}>
                        {trackerName === ModuleScheduleSection.ARTICLE_MATCHES ? (
                            <BookmakerLogo
                                alt={bookmaker?.name || ''}
                                url={url || bookmaker?.url || ''}
                                src={icon || ''}
                                className={styles['betting-logo']}
                            />
                        ) : null}
                        <ul className={styles['betting-odds']}>
                            <MatchOdds threeWayOdds={odds} classes={styles['row-item']} />
                        </ul>
                    </div>
                    <div className={styles['disclaimer-row']}>
                        <BettingDisclaimer logoVariant={LogoVariant.TRANSPARENT} />
                    </div>
                </>
            ) : null}
        </section>
    );
};
